// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-card-post-js": () => import("./../src/templates/card-post.js" /* webpackChunkName: "component---src-templates-card-post-js" */),
  "component---src-templates-donate-page-js": () => import("./../src/templates/donate-page.js" /* webpackChunkName: "component---src-templates-donate-page-js" */),
  "component---src-templates-fortune-post-js": () => import("./../src/templates/fortune-post.js" /* webpackChunkName: "component---src-templates-fortune-post-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-page-js": () => import("./../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-archives-page-js": () => import("./../src/templates/archives-page.js" /* webpackChunkName: "component---src-templates-archives-page-js" */),
  "component---src-templates-topics-js": () => import("./../src/templates/topics.js" /* webpackChunkName: "component---src-templates-topics-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-login-js": () => import("./../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-articles-index-js": () => import("./../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-cards-index-js": () => import("./../src/pages/cards/index.js" /* webpackChunkName: "component---src-pages-cards-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-foo-index-js": () => import("./../src/pages/foo/index.js" /* webpackChunkName: "component---src-pages-foo-index-js" */),
  "component---src-pages-fortune-index-js": () => import("./../src/pages/fortune/index.js" /* webpackChunkName: "component---src-pages-fortune-index-js" */),
  "component---src-pages-fortune-result-js": () => import("./../src/pages/fortune/result.js" /* webpackChunkName: "component---src-pages-fortune-result-js" */),
  "component---src-pages-resources-index-js": () => import("./../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-topics-index-js": () => import("./../src/pages/topics/index.js" /* webpackChunkName: "component---src-pages-topics-index-js" */)
}

